.bgOpacity {
  /* styles.css or your CSS file */
  width: 100%;
  height: 100%;
  background-image: url('../images/paintCans.webp');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  opacity: 0.8;
  position: fixed;
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  z-index: -1;
  /* background-position: center; */

  
}
